/**
 * External dependencies
 */
import { useState } from 'react';
import { decodeEntities } from '@wordpress/html-entities';
import { stripHtml } from 'string-strip-html';

/**
 * Internal dependencies
 */
import { useLogoutMutation } from './mutations/useLogoutMutation';
import { useLoginMutation } from './mutations/useLoginMutation';
import { useViewerQuery } from './queries/useViewerQuery';
import { useCustomerQuery } from './queries/useCustomerQuery';
// import { useAuthContext } from 'context';
import { useAppState } from '../components/context';
import { useSafeDispatch } from './useSafeDispatch';
import { navigate } from 'gatsby';

const errorCodes = {
  invalid_username: 'Ogiltigt användarnamn eller e-postadress. Försök igen.',
  invalid_email: 'Ogiltig e-postadress. Försök igen.',
  incorrect_password: 'Felaktigt lösenord. Försök igen eller återställ lösenord.',
  // empty_username: 'Please provide your username.',
  empty_email: 'Fyll i din e-postadress.',
  empty_password: 'Fyll i ditt lösenord.',
};

/**
 * Hook which tracks if the user is logged in.
 */
export const useAuth = () => {
  // const { isLoggedIn, setIsLoggedIn } = useAuthContext();
  const { isLoggedIn, setIsLoggedIn, setCustomerData } = useAppState();
  const [error, setError] = useState(null);
  const [status, setStatus] = useState('idle');
  const { logoutMutation } = useLogoutMutation();
  const { loginMutation } = useLoginMutation();
  const {
    data: viewer,
    refetch: refetchViewer,
    loading: loadingViewer,
  } = useViewerQuery();

  const {
    data: customer,
    refetch: refetchCustomer,
    loading: loadingCustomer,
  } = useCustomerQuery();

  const onLoginSuccess = useSafeDispatch(() => {
    setIsLoggedIn(true);
    setStatus('resolved');
    navigate("/user")
  });

  const onLogoutSuccess = useSafeDispatch(() => {
    setIsLoggedIn(false);
    setCustomerData(null)
    setStatus('resolved');
    
    // navigate("/login");
  });

  const onError = useSafeDispatch((errors) => {
    setError(
      errorCodes[errors.message] ||
      `${stripHtml(decodeEntities(errors.message)).result}`
    );
    setStatus('resolved');
  });

  const login = (username, password) => {
    setError(null);
    setStatus('resolving');
    return loginMutation(username, password)
      .then(onLoginSuccess)
      .catch(onError);
  };

  const logout = () => {
    setStatus('resolving');
    return logoutMutation().then(onLogoutSuccess).catch(onError);
  };

  return {
    login,
    logout,
    isLoggedIn,
    refetchViewer,
    loadingViewer,
    viewer,
    customer,
    refetchCustomer,
    loadingCustomer,
    error,
    status,
  };
};
