import { gql, useMutation } from '@apollo/client';

const LOGIN = gql`
	mutation Login($login: String!, $password: String!) {
		loginWithCookies(input: { login: $login, password: $password }) {
			status
		}
	}
`;

export const useLoginMutation = () => {
  const [mutation, mutationResults] = useMutation(LOGIN);

  const loginMutation = (email, password) => {
    return mutation({
      variables: {
        login: email,
        password,
      },
    });
  };

  return { loginMutation, results: mutationResults };
};
