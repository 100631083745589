/**
 * External dependencies
 */
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useEffect } from 'react';

/**
 * Internal dependencies
 */
// import { useAuthContext } from '../../components/context';
import { useAppState } from '../../components/context';
import { useSafeDispatch } from '../useSafeDispatch'

const CUSTOMER = gql`
	query viewer {
    customerData {
      id
      firstName
      lastName
      company
      country
      email
      postalAddress
      streetAddress
      vat
      orgNumber
      card
      downloads {
        name
        url
      }
      subscription {
        endDate
        nextPaymentDate
        startDate
        subscriptionType
        trialEndDate
        license {
          key
          total
        }
        priceTotal
        priceSubTotal
        priceForUser
        paymentOrderId
      }
    }
  }
`;

/**
 * Hook which gets details about the logged in user.
 */
export const useCustomerQuery = () => {
  // const { setIsLoggedIn, isLoggedIn } = useAuthContext();
  const { setIsLoggedIn, isLoggedIn, setCustomerData, customerData } = useAppState();
  const [verifyCustomer] = useMutation(VERIFY_CUSTOMER, {variables: {input: {clientMutationId: "123"}}})
  const isBrowser = () => typeof window !== "undefined"
  useEffect(() => {
    if (isLoggedIn) {
      // verifyCustomer().then((res) => { console.log(res); getCustomer()})
      if (isBrowser && window.location.pathname === "/user") {
        verifyCustomer().then((res) => getCustomer())
      }
      else {
        getCustomer();
      }
      
    }
  }, [isLoggedIn]);

  const onError = useSafeDispatch((err) => {
    setIsLoggedIn(false);
    console.log(err);
    console.log('customerData error 1');
  });

  const onCompleted = useSafeDispatch((theData) => {
    // console.log(theData);
    // theData && !customerData && setCustomerData(theData.customerData)
    theData && setCustomerData(theData.customerData)
    if (!theData?.customerData) {
      setIsLoggedIn(false);
      setCustomerData(null)
      console.log('customerData error 2');
    }
  });

  const [getCustomer, { loading, error, data }] = useLazyQuery(CUSTOMER, {
    fetchPolicy: 'network-only',
    onError,
    onCompleted,
  });

  return {
    loading,
    error,
    data: data && data.customerData ? data.customerData : null,
    getCustomer,
  };
};

const VERIFY_CUSTOMER = gql`
  mutation($input: VerifyCustomerStatusInput!) {
    verifyCustomerStatus(input: $input) {
      mutationStatus
    }
  }
`
